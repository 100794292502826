<script lang="ts" setup>
import ls from 'localstorage-slim'
import { Ref, Teleport, computed, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import MegaModal from '@ankor-io/blocks/components/modals/MegaModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import ListGrid from '@ankor-io/blocks/layouts/ListGrid.vue'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { OutlinePerson, OutlineSleeping } from '@ankor-io/icons/outline'
import { SolidPlus, SolidX } from '@ankor-io/icons/solid'
import { SolidSearch } from '@ankor-io/icons/solid'

import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import VisibleObserver from '@/components/VisibleObserver.vue'

import Tags from '../library/Tags.vue'
import VesselPreview from '../library/VesselPreview.vue'

const UNNAMED_YACHT = 'Unnamed Yacht'

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null && ls.get<string>(`tombstone::${hit.uri}`) !== undefined) {
      dirtyRiver.value[offset] = null
      return
    }

    // work out the hero image url
    let _hero
    if (hit.hero && hit.hero.startsWith(`${URIContext.MEDIA}::`)) {
      _hero = `/media/${hit.hero}`
    } else {
      _hero = `/media/${new MediaUriBuilder().build(hit.uri, hit.hero)}`
    }

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      ...hit,
      uri: hit.objectID,
      hero: _hero,
      name: hit.line_1,
      length: hit.line_2,
      numCabins: hit.line_3,
      numSleeps: hit.line_4,
      buildYear: hit.line_5,
      builder: hit.line_6,
      tags: hit.tags,
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

//
// Modal helpers
const previewModalHelper = modalHelper()
</script>
<template>
  <div class="flex gap-2 flex-col md:flex-row h-[calc(100vh-7rem)]">
    <div class="w-full md:w-3/4 h-3/4 md:h-full overflow-auto">
      <ais-configure filters="type:vessel AND NOT tags:tombstone" hitsPerPage="60" />

      <!-- Preview modal -->
      <Teleport defer to="body">
        <MegaModal
          v-if="previewModalHelper.isVisible()"
          :model="previewModalHelper.model"
          @dismiss="previewModalHelper.hide()"
        >
          <template #header>
            <div class="flex justify-between items-center">
              <h4>Yacht Details</h4>

              <div class="flex items-center gap-x-2">
                <button
                  class="w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300"
                >
                  <SolidPlus class="size-5 mr-2 self-center text-white" />
                  Select Yacht
                </button>

                <!-- FIXME: add this X to the modal so that it does not scroll away -->
                <button
                  type="button"
                  class="transition-colors rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-500"
                  @click="previewModalHelper.hide()"
                >
                  <span class="sr-only">Close</span>
                  <SolidX class="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </template>

          <template #default="{ modelValue }">
            <div class="min-h-24 overflow-y-auto border-t border-gray-200 dark:border-gray-600">
              <VesselPreview :uri="modelValue.uri" />
            </div>
          </template>
        </MegaModal>
      </Teleport>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <h3 class="sm:h-10" data-testId="page-title">Select Yachts</h3>
          <p data-testId="page-subtitle">Select one or more yachts that you wish to present.</p>
        </div>

        <div class="w-full flex flex-col md:flex-row justify-between items-baseline gap-2 pb-4 min-h-11">
          <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-3">
            <div class="w-full sm:w-auto flex gap-x-2 items-center">
              <!-- nunununununununun -->
              <!--   Category Box    -->
              <!-- nunununununununun -->
              <ais-menu-select
                attribute="tags"
                :class-names="{
                  'ais-MenuSelect': 'tags-selector',
                }"
              >
                <template v-slot="{ refine }">
                  <select
                    class="w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                    @change="refine(($event.currentTarget! as HTMLInputElement).value)"
                  >
                    <option class="" value="">All Yachts</option>
                    <option class="" value="claimed">CA Managed</option>
                    <option class="" value="ankor">Ankor Managed</option>
                  </select>
                </template>
              </ais-menu-select>

              <!-- nunununununununun -->
              <!--    Search Box     -->
              <!-- nunununununununun -->
              <ais-search-box
                placeholder="Search for a yacht..."
                submit-title="Submit"
                reset-title="clear"
                :autofocus="true"
                :show-loading-indicator="false"
                :class-names="{
                  'ais-SearchBox': 'h-11 w-full',
                  'ais-SearchBox-form':
                    'p-3 h-full flex items-center gap-x-2 relative rounded-lg border bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
                  'ais-SearchBox-input':
                    'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-gray-500',
                  'ais-SearchBox-reset': 'hidden',
                  'ais-SearchBox-loadingIcon': 'hidden',
                }"
              >
                <template v-slot:submit-icon>
                  <SolidSearch
                    class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
                  />
                </template>
              </ais-search-box>
            </div>
          </div>
        </div>
      </div>

      <!-- nunununununununun -->
      <!--  Search Results   -->
      <!-- nunununununununun -->
      <ais-infinite-hits :transform-items="accumulateSearchResults">
        <template v-slot="{ refineNext, isLastPage, results }">
          <div id="searchResults-top"></div>

          <!-- Getting Started and No Results-->
          <!-- add '?showGetStarted=1' to show that screen manually -->
          <template v-if="!results.nbHits || $route.query.showGetStarted">
            <slot name="no-results">
              <!-- No results. -->
              <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
                <div class="h-[33vh]"><img :src="SearchNoResultsMoon" alt="No results found" class="h-full" /></div>
                <h4>No results.</h4>
                <div>Sorry! We could not find anything to match your query.</div>
              </div>
            </slot>
          </template>

          <!-- Show results -->
          <template v-else>
            <ListGrid :keyField="'uri'" :items="river">
              <template #item="{ item }">
                <!-- Yacht Card -->
                <div>
                  <button
                    type="button"
                    class="w-full aspect-[1.44] relative hover:scale-[1.02] md:hover:scale-105 focus:ring-4 focus:outline-none focus:ring-primary-300 cursor-pointer rounded-lg transition-transform duration-500 ease-in-out"
                    aria-label="Preview Yacht"
                    @click="
                      previewModalHelper.show({
                        uri: item.uri,
                        name: item.name,
                        length: item.length,
                        buildYear: item.buildYear,
                        numCabins: item.numCabins,
                        builder: item.builder,
                        tags: item.tags,
                      })
                    "
                  >
                    <div v-if="item.tags" class="absolute top-2 left-2">
                      <Tags
                        :tags="item.tags"
                        :only="['claimed', 'claimed_ca', 'ankor']"
                        :labels="{ ankor: 'Ankor Managed' }"
                      />
                    </div>
                    <AssetViewerPlaceholder
                      class="w-full object-cover shadow-md rounded-lg"
                      :url="item.hero"
                      :blur="item.blur"
                      :widthDescriptors="['320w']"
                    />
                  </button>
                  <!-- Yacht Card Text Section -->
                  <div class="mt-2 flex gap-x-1">
                    <h6 class="w-full self-center line-clamp">
                      {{ item.name || UNNAMED_YACHT }}
                    </h6>
                    <YachtsActionsKebab :uri="item.uri" />
                  </div>
                  <div class="flex flex-row items-center mb-2 gap-x-2.5 text-sm text-gray-400 dark:text-white">
                    <span v-if="item.length" class="text-sm text-gray-400">
                      {{ item.length }}m ({{ (item.length * 3.2808).toFixed(1) }}ft)
                    </span>
                    <div v-if="item.numCabins" class="flex items-center self-start gap-x-1.5">
                      <OutlineSleeping class="size-4 stroke-gray-500 dark:stroke-gray-400" />
                      <span class="text-gray-500 dark:text-gray-400">{{ item.numCabins }}</span>
                    </div>
                    <div v-if="item.numSleeps" class="flex items-center self-start gap-x-1.5">
                      <OutlinePerson class="size-4 stroke-gray-500 dark:stroke-gray-400" />
                      <span class="text-gray-500 dark:text-gray-400">{{ item.numSleeps }}</span>
                    </div>
                  </div>
                  <button
                    class="w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300"
                  >
                    <SolidPlus class="size-5 mr-1 self-center text-white" />
                    Select Yacht
                  </button>
                </div>
              </template>
              <template #after>
                <template v-if="!isLastPage">
                  <VisibleObserver @visible="refineNext" />
                </template>
                <template v-else="isLastPage">
                  <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                </template>
              </template>
            </ListGrid>
          </template>
        </template>
      </ais-infinite-hits>
    </div>
    <div class="w-full md:w-1/4 h-1/4 md:h-full">
      <div class="bg-gray-100 dark:bg-gray-800 h-full rounded-lg">
        <p class="p-4">Selected Yachts</p>
      </div>
    </div>
  </div>
</template>
